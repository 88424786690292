import { createWebHistory, createRouter } from 'vue-router'
import AppLogin from '@/views/AppLogin.vue'


const routes = [
  {
    path: '/login',
    name: 'login',
    component: AppLogin,
    meta: {
      omitAuth: true // login page doesn't need a login check!
    }
  },

  {
    path: '/', redirect: { name: 'index' }
  }, {
    path: '/newsboard',
    name: "index",
    component: () => import(/* webpackChunkName: "newsboard" */ '@/views/IndexView.vue'),
  },

  {
    path: '/map',
    name: 'destination-map',
    component: () => import(/* webpackChunkName: "maps" */ '@/views/DestinationMapView.vue'),
  },

  {
    path: '/entryrestrictions',
    name: 'entryrestrictions',
    component: () => import(/* webpackChunkName: "entryrestrictions" */ '@/views/EntryRestriction/EntryRestrictionsView.vue'),
  },{
    path: '/entryrestrictions/:entryRestrictionId/edit',
    name: 'entryrestriction-edit',
    component: () => import(/* webpackChunkName: "entryrestrictions" */ '@/views/EntryRestriction/EntryRestrictionEditView.vue'),
  },

  {
    path: '/informationcenter',
    name: 'informationcenter',
    component: () => import(/* webpackChunkName: "informationcenter" */ '@/views/InformationCenter.vue'),
  },

  {
    path: '/countries-information',
    name: 'countries-information',
    component: () => import(/* webpackChunkName: "countries-information" */ '@/views/CountriesInformationView.vue'),
  },

  {
    path: '/risk-matrix',
    name: 'risk-matrix',
    component: () => import(/* webpackChunkName: "risk-matrix" */ '@/views/RiskMatrixView.vue'),
  },

  {
    path: '/mytrip-history',
    name: 'mytrip-history',
    component: () => import(/* webpackChunkName: "mytrip-history" */ '@/views/MyJourneyHistory.vue'),
  },

  {
    path: '/events',
    name: 'events',
    component: () => import(/* webpackChunkName: "events" */ '@/views/EventsView.vue'),
  },

  {
    path: '/events-map',
    name: 'events-map',
    component: () => import(/* webpackChunkName: "events-map" */ '@/views/EventsMapView.vue'),
  },

  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '@/views/NewsView.vue'),
  },

  {
    path: '/news-admin/list',
    name: 'news-admin-list',
    component: () => import(/* webpackChunkName: "news-admin" */ '@/views/NewsAdmin/NewsAdminList.vue'),
  }, {
    path: '/news-admin/edit/:newsId',
    name: 'news-admin-edit',
    component: () => import(/* webpackChunkName: "news-admin" */ '@/views/NewsAdmin/NewsAdminEdit.vue'),
  }, {
    path: '/news-admin/add/',
    name: 'news-admin-add',
    component: () => import(/* webpackChunkName: "news-admin" */ '@/views/NewsAdmin/NewsAdminEdit.vue'),
  },

  {
    path: '/organisation/countries-selection',
    component: () => import(/* webpackChunkName: "countries-selection" */ '@/views/SettingsView.vue'),
    children: [{
      path: '',
      name: 'organisation-countries-selection',
      component: () => import(/* webpackChunkName: "countries-selection" */ '@/views/Organisation/CountrySelectionView.vue'),
    }],
  },

  {
    path: '/organisation/mytrip-settings',
    component: () => import(/* webpackChunkName: "filter-settings" */ '@/views/SettingsView.vue'),
    children: [{
      path: '',
      name: 'mytrip-filter-settings',
      component: () => import(/* webpackChunkName: "filter-settings" */ '@/views/Organisation/MyTripFilterSettingsView.vue'),
    }],
  },

  {
    path: '/profile/edit',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/SettingsView.vue'),
    children: [{
      path: '',
      name: 'profile-edit',
      component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile/EditProfileView.vue'),
    },],
  },

  {
    path: '/textsnippets/list',
    name: 'textsnippet-list',
    component: () => import(/* webpackChunkName: "textsnippets" */ '@/views/TextSnippet/TextSnippetList.vue'),
  }, {
    path: '/textsnippets/edit/:textSnippetId',
    name: 'textsnippet-edit',
    component: () => import(/* webpackChunkName: "textsnippets" */ '@/views/TextSnippet/TextSnippetEdit.vue'),
  }, {
    path: '/textsnippets/add/',
    name: 'textsnippet-add',
    component: () => import(/* webpackChunkName: "textsnippets" */ '@/views/TextSnippet/TextSnippetEdit.vue'),
  }, {
    path: '/destination/overview/',
    name: 'destination-result',
    meta: {
      mainMenu: "destination",
    },
    component: () => import(/* webpackChunkName: "destination" */ '@/views/Destination/DestinationResultView.vue'),
  },

  {
    path: '/internal/entryrestrictionoverview/',
    name: 'internal-entryrestriction-overview',
    component: () => import(/* webpackChunkName: "internal" */ '@/views/Internal/EntryRestrictionOverview.vue'),
  },
]


const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // We do an auth check for all pages except the ones with omitAuth = True
  // like the login page
  if (!to.matched.some(record => record.meta.omitAuth)) {
    if (localStorage.getItem("authToken")) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
